import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useQuery, useMutation } from 'react-query';
import { path, any, propEq } from 'ramda';
import axios from 'axios';

import Vacancy from './Vacancy';
import { COMPANY, DOMAIN } from '../constants/domain';
import { getUtmFromSearch, getUtmTool } from '../utils/utm';

const CLASSIFIER_TITLE = {
    cook: 'Повар',
    cleaner: 'Мойщик',
    operator: 'Оператор АЗС',
};

const VACANCY_TITLE = {
    cook: 'Повар',
    cleaner: 'Мойщик посуды',
    operator: 'Оператор АЗС',
    'cleaner-success': 'Мойщик',
    'cook-success': 'Повар',
    'operator-success': 'Оператор',
};

const VacancyList = ({ vacancy, history }) => {
    const [modal, setModal] = useState(vacancy || null);

    const mutation = useMutation(({ vacancy, domain, values }) => {
        const url = vacancy ? `vacancy/${vacancy}/${domain}` : `domain/${domain}`;

        return axios.post(`/api/public/${url}/applicant`, {
            ...values,
            _utm: getUtmFromSearch(),
            _tool: getUtmTool(),
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'ru',
            }
        })
    });
    const { data : regionsData } = useQuery("regions", () =>
        axios.get(`/api/region`, {
            params: {
                pagination: {
                    limit: 0,
                }
            }
        }).then((res) => res.data)
    );
    const { isLoading, error, data } = useQuery("vacancies", () =>
        axios.get(`/api/public/vacancy`, {
            params: {
                filter: {
                    companyCode: COMPANY,
                    domainName: DOMAIN,
                },
                relations: ['cities', 'classifiers', 'domains', 'domains.company', 'domains.vacancyFormPreset']
            }
        }).then((res) => res.data)
    );

    const regions = path(['items'], regionsData);
    const vacancies = (path(['items'], data) || []).filter(item => {
        const classifiers = path(['_relations', 'classifiers'], item);
        return any(propEq('name', CLASSIFIER_TITLE[modal]), classifiers);
    });
    const domainForm = path(['items', 0, '_relations', 'domains', 0], data) || {};

    useEffect(() => {
        if (!VACANCY_TITLE[vacancy]) {
            setModal(null);
            history.replace('/');
        }
    }, [vacancy, history]);

    useEffect(() => {
        if (mutation.isSuccess) {
            setModal(`${modal}-success`);
            history.push(`/${modal}-success`);
            mutation.reset();
        }
    }, [mutation.isSuccess, modal, mutation, history]);

    const openModal = modal => {
        history.push(`/${modal}`);
        setModal(modal);
    };
    const closeModal = () => {
        history.push(`/`);
        setModal(null);
    };

    return (
      <div className="vacancy-list">
          <div className="container">
            {/* eslint-disable-next-line */}
            <a name="vacancies" id="vacancies" />
            <h2>ВАКАНСИИ</h2>
          </div>
          <div className="row row-wrap">
            <div className="col-1">
                <div className="content">
                    <div>
                        <h3>Оператор АЗС</h3>
                        <div className="item">
                            <div className="title">ОБЯЗАННОСТИ</div>
                            <ul>
                                <li>обслуживание и консультирование посетителей АЗС</li>
                                <li>информирование посетителей о действующих акциях</li>
                                <li>прием оплаты на кассе (наличный / безналичный расчет)</li>
                                <li>прием товаров, выкладка продукции в торговом зале магазина, участие в инвентаризациях</li>
                                <li>поддержание чистоты</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="item">
                            <div className="title">УСЛОВИЯ</div>
                            <ul>
                                <li>трудоустройство по ТК РФ</li>
                                <li>стабильная заработная плата</li>
                                <li>сменный график</li>
                                <li>оплачиваемое корпоративное обучение по профессии</li>
                                <li>полный социальный пакет</li>
                            </ul>
                        </div>
                        <div className="item">
                            <div className="title">ТРЕБОВАНИЯ</div>
                            <ul>
                                <li>наличие медицинской книжки (возможность получения за счет компании)</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="controls">
                    <button className="btn btn-outline btn-black" onClick={() => openModal('operator')}>ОТКЛИКНУТЬСЯ</button>
                </div>
            </div>
            <div className="col-2">
                <div className="content">
                    <div>
                        <h3>Повар</h3>
                        <div className="item">
                            <div className="title">ОБЯЗАННОСТИ</div>
                            <ul>
                                <li>контроль качества приготовления, безопасности и маркировки блюд и напитков</li>
                                <li>контроль хранения и расхода продуктов на производстве</li>
                                <li>приготовление качественных блюд, напитков на АЗС</li>
                                <li>поддержка высокого уровня чистоты оборудования, инвентаря и гигиены</li>
                                <li>соблюдение техники безопасности, знание правил работы на тепловом и механическом оборудовании</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="item">
                            <div className="title">УСЛОВИЯ</div>
                            <ul>
                                <li>трудоустройство по ТК РФ</li>
                                <li>стабильная заработная плата</li>
                                <li>сменный график</li>
                                <li>корпоративное обучение</li>
                                <li>полный социальный пакет</li>
                            </ul>
                        </div>
                        <div className="item">
                            <div className="title">ТРЕБОВАНИЯ</div>
                            <ul>
                                <li>опыт работы от 1 года</li>
                                <li>наличие медицинской книжки (есть возможность получения)</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="controls">
                <button className="btn btn-outline" onClick={() => openModal('cook')}>ОТКЛИКНУТЬСЯ</button>
                </div>
            </div>
            <div className="col-1">
                <div className="content">
                    <div>
                        <h3>МОЙЩИК ПОСУДЫ</h3>
                        <div className="item">
                            <div className="title">ОБЯЗАННОСТИ</div>
                            <ul>
                                <li>обеспечение содержания в чистоте:</li>
                                <li>
                                    <ul>
                                        <li>рабочей зоны</li>
                                        <li>столовой</li>
                                        <li>кухонной посуды</li>
                                        <li>приборов</li>
                                        <li>производственного инвентаря в кафе</li>
                                    </ul>
                                </li>
                                <li>на АЗС «ЛУКОЙЛ» в течение всего рабочего дня</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="item">
                            <div className="title">УСЛОВИЯ</div>
                            <ul>
                                <li>трудоустройство по ТК РФ</li>
                                <li>стабильная заработная плата</li>
                                <li>сменный график</li>
                                <li>корпоративное обучение</li>
                                <li>полный социальный пакет</li>
                            </ul>
                        </div>
                        <div className="item">
                            <div className="title">ТРЕБОВАНИЯ</div>
                            <ul>
                                <li>наличие медицинской книжки (есть возможность получения)</li>
                                <li className="hidden">опыт работы от 1 года</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="controls">
                    <button className="btn btn-outline  btn-black" onClick={() => openModal('cleaner')}>ОТКЛИКНУТЬСЯ</button>
                </div>
            </div>
          </div>
        <Modal isOpen={!!modal} onRequestClose={closeModal}>
            <div className="modal-header">
                <h3>{ VACANCY_TITLE[modal] || '' }</h3>
                <button className="btn-close" onClick={closeModal}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24 8L8 24" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8 8L24 24" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className="modal-body">
                { CLASSIFIER_TITLE[modal] ? (
                    error || (!isLoading && !vacancies.length) ? (
                        <p style={{ 'text-align': 'center' }}>
                            { error ? (
                                'Не удалось загрузить вакансию, попробуйте обновить страницу'
                            ) : (
                                'Нет доступных вакансий'
                            )}
                        </p>
                    ) : (
                        isLoading ? 'Загрузка вакансии...' : (
                            <Vacancy
                                mutation={mutation}
                                classifier={modal}
                                vacancies={vacancies}
                                domain={domainForm}
                                regions={regions}
                            />
                        )
                    )
                ) : (
                    <h2>ВАША АНКЕТА НАПРАВЛЕНА В ОТДЕЛ ПО РАБОТЕ С ПЕРСОНАЛОМ</h2>
                )}
            </div>
        </Modal>
      </div>
  );
}

export default VacancyList;
