import React from 'react';

const BoxRed = () => {
  return (
      <div className="box-red">
          <div className="container">
              {/* eslint-disable-next-line */}
              <a name="form" id="form" />
              <h2>СТАНОВИСЬ ЧАСТЬЮ КОМАНДЫ — <br/> ОСТАВЛЯЙ ЗАЯВКУ</h2>
              <p>Будь всегда в движении, развивайся в ЛУКОЙЛ.</p>
              {/*<button className="btn btn-outline">ОСТАВИТЬ ЗАЯВКУ</button>*/}
          </div>
      </div>
  );
}

export default BoxRed;
