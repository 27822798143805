import React from 'react';
import message from '../assets/img/message.svg';

const Footer = () => {
  return (
      <div className="footer">
        <div className="container">
          <div className="row">
            <div>ООО "ЛИКАРД"</div>
            <div>
              <span>Если есть  вопросы, мы всегда рады ответить.</span>
              <a href="mailto:Valentina.Akhmetshina@lukoil.com"><img src={message} alt="message"/></a>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Footer;
