import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useLocation } from "react-router-dom";
import 'react-dropdown/style.css';

import head from '../assets/img/head.jpg';
import logo from '../assets/img/logo.svg';

const Header = () => {
  const location = useLocation();

  return (
      <div className="header">
          <div className="row">
              <div className="col-1">
                  <div className="content">
                      <a href="/"><img className="logo" src={logo} alt=""/></a>
                      <h1>РАБОТА НА<br/> АЗС ЛУКОЙЛ</h1>
                      <a href="/#vacancies" className="btn btn-outline btn-white">Вакансии</a>
                  </div>
              </div>
              <div className="col-2">
                  <div className="content">
                      <Menu key={`${location.pathname}/${location.hash}`}>
                          <div className="nav">
                              <ul className="menu">
                                  <li><a href="/#terms">УСЛОВИЯ</a></li>
                                  <li><a href="/#vacancies">ВАКАНСИИ</a></li>
                                  <li><a href="/#form">АНКЕТА</a></li>
                              </ul>
                          </div>
                      </Menu>
                  </div>
                  <img className="head" src={head} alt=""/>
              </div>
          </div>
      </div>
  );
}

export default Header;
