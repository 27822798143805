import React, { Component } from 'react';
import { any, path, pathOr, propEq, find } from 'ramda';
import Form from '@experium/findy-form';

import '@experium/findy-form/lib/styles/index.css';

import { VACANCY_REGIONS, VACANCY_CLASSIFIERS } from '../constants/domain';

const VacancyForm = ({ domain, mutation, onSubmit }) => {
    return <Form
        fields={path(['_relations', 'vacancyFormPreset', 'questions'], domain)}
        onSubmit={onSubmit}
        opd={pathOr('', ['_relations', 'company', 'pdaSettings', 'text'], domain)}
        htmlOpdStyles={'.opd-html-form div > div { text-align: justify; }'}
        opdSettings={pathOr({}, ['_relations', 'company', 'pdaSettings'], domain)}
        allowFileExtensions={path(['_relations', 'company', 'companySettings', 'allowFileExtensions'], domain)}
        captcha={path(['_relations', 'company', 'companySettings', 'captcha', 'domains'], domain)}
        captchaOptions={path(['_relations', 'domainFormPreset', 'options'], domain)}
        company={pathOr({}, ['_relations', 'company', 'id'], domain)}
        language='ru'
        serverErrors={mutation.error}
        submitting={mutation.isLoading}
        scrollContainerClassName={'.modal-body'}
    />;
}

export default class Vacancy extends Component {
    onSubmit = (values, formProps) => {
        const domain = this.props.domain.id;
        const region = find(propEq('id', values.region), this.props.regions);

        if (region && VACANCY_REGIONS[region.name]) {
            const name = VACANCY_CLASSIFIERS[VACANCY_REGIONS[region.name]];
            const vacancy = find(item => any(propEq('name', name), path(['_relations', 'classifiers'], item)), this.props.vacancies);
            const id = path(['id'], vacancy);

            if (id) {
                return this.props.mutation.mutate({
                    vacancy: id,
                    domain,
                    values,
                });
            }
        }

        this.props.mutation.mutate({ vacancy: null, domain, values });
    }

    render() {
        return (
            <div className="wrap-form">
                <VacancyForm {...this.props} onSubmit={this.onSubmit} />
            </div>
        );
    }
}
