import React from 'react';

const Conditions = () => {
  return (
      <div className="conditions">
          {/* eslint-disable-next-line */}
          <a name="terms" id="terms" />
          <div className="row">
              <div className="col-1-4">
                  <div className="content">
                    <h2>ПЛЮСЫ <br/>РАБОТЫ <br/>В&nbsp;ЛУКОЙЛ</h2>
                      <p>ОСТАВЬ ЗАЯВКУ НА&nbsp;САЙТЕ И&nbsp;ПОЛУЧИ ПОЛНЫЙ ПАКЕТ ПРЕИМУЩЕСТВ ЛУКОЙЛ</p>
                  </div>
              </div>
              <div className="col-3-4">
                  <div className="item">
                      <h3>ПОЛИС ДМС</h3>
                      <p>Каждый сотрудник получает бесплатное медицинское страхование</p>
                  </div>
                  <div className="item" />
                  <div className="item">
                      <h3>ДЕТСКИЕ ПУТЕВКИ</h3>
                      <p>Ваши дети смогут получать бесплатные путевки в летние лагеря</p>
                  </div>
                  <div className="item" />
                  <div className="item">
                      <h3>СМЕННЫЙ ГРАФИК</h3>
                      <p>График работы 2/2, подберем АЗС ближе к вашему дому</p>
                  </div>
                  <div className="item" />
                  <div className="item">
                      <h3>КОНКУРЕНТНАЯ ЗАРАБОТНАЯ ПЛАТА</h3>
                      <p>Наши коллеги получают доход выше среднего по региону</p>
                  </div>
                  <div className="item" />
                  <div className="item">
                      <h3>ОБУЧЕНИЕ</h3>
                      <p>Сотрудники ЛУКОЙЛ обучаются за счет компании</p>
                  </div>
                  <div className="item" />
              </div>
          </div>
      </div>
  );
}

export default Conditions;
